import React from 'react'
import '../styles/About.css'
import profile_pic from '../media/memoji.png'
import { Link } from 'react-router-dom'



const About = () => {
  return (
    <div className='screen' id='about'>
      <div className='about-main'>
        <img className='large' src={profile_pic} alt='emoji of likuna'/>
        <div className='text'>
          <h3>I'm Lia </h3>
          <p  className='subtitle'>Your new favorite full-stack developer</p>
          <p>
            I am passionate about projects which put tech innovation to good use like building communities online, enabling small-scale producers, and solving for information asymmetries. 
            I am most comfortable with React and Rails frameworks, have worked with PostgreSQL, and am always on the lookout for the next thing to learn or to deepen my knowledge in. 
            These days my top priority project is <a id='PoemClub' href='https://www.thepoemclub.com/'>Poem Club</a>
          </p>
        </div>
      </div>
    </div>
  )

}

export default About;