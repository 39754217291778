const spreadsheetId = "1hlHYuqE2ZBDzWyrXm7Cy5w1A_nvZT4YOR7EWR1Zl-WI";

const apiKey= "AIzaSyDl2Dx9NWVXkvI0geV5ttHAT_QeZWKz6H8"

const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/Sheet1?key=${apiKey}`;


export const getData = async () => {
  const response = await fetch(url, {

  }).then((response) => response.json());
  return response.values;
};
