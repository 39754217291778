import React, { Component } from 'react';

class LeftArrow extends Component {
  render() {
    return(
      <div className='arrow' onClick={this.props.goToPrevSlide}>
        <h2 className='arrowText'>Previous</h2>
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm-1-17v4h8v2h-8v4l-6-5 6-5z"/></svg>
      </div>
    )
  }
}
export default LeftArrow;