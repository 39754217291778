import React, { Component } from 'react';
import Slide from './slide';
import LeftArrow from './leftArrow';
import RightArrow from './rightArrow'
import './main.css';
import { getData } from "../../services/api";

export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      projectData: ""
    };
  }

  componentDidMount = async () => {
    const response = await getData();
    this.setState({
      projectData: response.reverse(),
    });
    console.log(this.state.projectData)
  };

  goToPrevSlide() {
    let index = this.state.activeIndex;
    let length = this.state.projectData.length;
    if (index < 1) {
      index = length - 1;
    }
    else {
      index--;
    }
    this.setState({
      activeIndex: index
    });
  }

  goToNextSlide() {
    let index = this.state.activeIndex;
    let length = this.state.projectData.length;
    if (index === length - 1) {
      index = 0
    }
    else {
      index++;
    }
    this.setState({
      activeIndex: index
    });
  }
  
  render() {
    // const data = this.state.projectData ? this.state.projectData : []
    const slide = !this.state.projectData ? '' : (
      <Slide
        activeIndex={this.state.activeIndex}
        data={this.state.projectData}
        />
    ) 
    return (
      <div className='carousel'>
          <LeftArrow
            goToPrevSlide={() => this.goToPrevSlide()}
          />
    
          {slide}
          <RightArrow
            goToNextSlide={() => this.goToNextSlide()}
          />
      </div>
    );
  }
}
