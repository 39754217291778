import React, { Component } from "react";
import "./styles/App.css";
import Header from "./components/Header";
import Work from "./components/Work";
import About from './components/About'
import Contact from './components/Contact'

import { Route, withRouter } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <div className="App">
          <Header
            mode={this.state.styles}
            showPlay={this.showPlay}
            showWork={this.showWork}
          />
        <div className="main">
          <About/>
          <Work />
          <Contact/>
          </div>
      </div>
    );
  }
}

export default withRouter(App);
