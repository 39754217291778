import React from 'react'
import Projects from './Projects'
import '../styles/Work.css'
import Slider from './carousel/Slider'


const Work = () => {
  return (
    <div className='screen' id='work'>
      {/* <h2>These Are Some of My Favorite Projects</h2> */}
      {/* <Projects /> */}
      <Slider/>
    </div>
  )

}

export default Work;