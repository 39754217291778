import React, { Component } from 'react';

class LeftArrow extends Component {
  render() {
    return(
      <div className='arrow' onClick={this.props.goToNextSlide}>
          <h2 className='arrowText'>Next</h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z"/></svg>
      </div>
    )
  }
}
export default LeftArrow;